<script setup lang="ts">
/**
 * @author Giorgi Tseradze
 */

"use strict";
import { useMainStore } from "@/store/main/index";

import { sendEvent } from "@/composables/utils/sendEvent";

import AmoXIcon from "@/components/regularComponents/UIComponents/SVG/AmoXIcon.vue";
import AmoPhoneIcon from "@/components/regularComponents/UIComponents/SVG/AmoPhoneIcon.vue";
import AmoChatIcon from "@/components/regularComponents/UIComponents/SVG/AmoChatIcon.vue";
import AmoWhatsappIcon from "@/components/regularComponents/UIComponents/SVG/AmoWhatsappIcon.vue";
import AmoTelegramIcon from "@/components/regularComponents/UIComponents/SVG/AmoTelegramIcon.vue";
import BaseModal from "@/components/regularComponents/UIComponents/Modals/BaseModal.vue";
import BookingForm from "@/components/regularComponents/FormComponents/BookingForm.vue";

import { CookieInterface } from "~/store/main/types/StateType";

const mainStore = useMainStore();

const blockClick = ref(true);
const stopClick = ref(false);
const firstTime = ref(true);
const chatIconActive = ref(false);
const isSlideOpen = ref(false);
const isModalOpen = ref();
const amoLoaded = ref();
const isChatOpen = ref(false);

let intervalId: NodeJS.Timer;
const clickEvent = new MouseEvent("click", {
  bubbles: true,
  cancelable: true,
  view: window,
});

const onClose = () => {
  isSlideOpen.value = false;
  isModalOpen.value = false;
};

const btnClick = (name: string) => {
  isSlideOpen.value = false;

  // if (name === "whatsapp") {
  //   sendEvent("Book a Call - WhatsApp Selection");
  // }

  // if (name === "telegram") {
  //   sendEvent("Book a Call - Telegram Selection");
  // }
};

const openModal = () => {
  isModalOpen.value = true;

  // sendEvent("Book a Call - Contact Form Selection");

  sendEvent("form-call", { formType: "Contact form from widget" });
};

const handleButtonClick = () => {
  if (firstTime.value) {
    firstTime.value = false;
    setAmoScript();
    const elementToObserve = document.querySelector("body");
    const observer = new MutationObserver(() => {
      const mainElem: HTMLElement | null = document.querySelector(
        ".amo-button-holder.amo-vertical"
      );
      const buttonElem: HTMLElement | null = document.querySelector(
        ".amo-button.amo-button--main"
      );
      if (mainElem) {
        mainElem.style.zIndex = "9999";
      }
      if (buttonElem) {
        observer.disconnect();
        buttonElem.style.zIndex = "9999";
        buttonElem.style.width = "0px";
        amoLoaded.value = true;
      }
    });
    observer.observe(elementToObserve as Node, { childList: true });
    setTimeout(() => {
      blockClick.value = false;
    }, 3000);
  }
  if (isChatOpen.value) {
    const buttonElem = document.querySelector(".amo-button");
    if (!buttonElem) return;
    buttonElem.dispatchEvent(clickEvent);
    isChatOpen.value = false;
  } else {
    // if (!isSlideOpen.value) {
    //   sendEvent("Book a Call - Form Open");
    // }

    isSlideOpen.value = !isSlideOpen.value;
  }
};
const clickAmo = (buttonElem: Ref<HTMLElement>) => {
  isSlideOpen.value = false;
  buttonElem.value.dispatchEvent(clickEvent);
  if (mainStore.windowWidth > mainStore.breakpoints.mobile) {
    isChatOpen.value = true;
  }
};

const handleAmoClick = () => {
  // sendEvent("Book a Call - Online Chat Selection");

  const buttonElem = ref();
  if (blockClick.value) {
    if (stopClick.value) return;
    stopClick.value = true;
    watch(
      amoLoaded,
      (n) => {
        if (n) {
          setTimeout(() => {
            buttonElem.value = document.querySelector(".amo-button");
            if (buttonElem.value) {
              clickAmo(buttonElem);

              sendEvent("form-call", { formType: "Online chat" });

              stopClick.value = false;
            }
          }, 1000);
        }
      },
      { immediate: true }
    );
  } else {
    if (stopClick.value) return;
    buttonElem.value = document.querySelector(".amo-button");
    if (buttonElem.value) {
      clickAmo(buttonElem);

      sendEvent("form-call", { formType: "Online chat" });
    }
  }
};

const setAmoScript = () => {
  const cookies = useState<CookieInterface>("cookies");
  let allowedScripts = Object.keys(cookies?.value?.places as any)?.filter(
    (key) => key === "afterAction"
  );

  if (!allowedScripts?.length) return;

  allowedScripts?.forEach((key) => {
    const scriptHTML = atob((cookies.value?.places as any)?.[key]?.script);

    const scripts = scriptHTML.match(/<script(\n|.)*?<\/script>/g);

    if (scripts?.length) {
      for (let i = 0; i < scripts.length; i++) {
        const scriptContent = scripts[i]?.match(/<script[^>]*>((\n|.)*?)<\/script>/);

        if (scriptContent?.[1].includes("amo")) {
          const script = document.createElement("script");
          script.innerHTML = scriptContent?.[1];
          document.body.appendChild(script);
        }
      }
    }
  });
};

const contactInfo = computed(() => {
  const copy = { ...mainStore.widgets?.["social-buttons"] };
  delete copy?.title;
  return copy;
});

onBeforeMount(async () => {
  if (!mainStore.widgets?.["social-buttons"]) {
    await mainStore.setWidgets(["social-buttons"]);
  }
});

onMounted(() => {
  intervalId = setInterval(() => {
    chatIconActive.value = !chatIconActive.value;
  }, 2000);
  // chatIconActive.value = !true;
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <aside
    class="amo-crm d-flex dir-col pos-fixed"
    :class="{ 'amo-crm-open': isSlideOpen }"
  >
    <div
      v-if="!isSlideOpen && !isChatOpen && !mainStore?.isFlatPageOpen"
      @click="handleButtonClick"
      class="amo-crm__left pos-fixed"
    >
      <p class="uppercase" :lang="mainStore?.lang === 'ka' ? 'ka' : ''">
        {{ $t("greetings, if you have any questions, please feel free to contact us") }}
      </p>
      <div class="amo-crm__left-triangle pos-abs"></div>
    </div>
    <div
      :class="{ 'ev-none': !isSlideOpen || isChatOpen }"
      class="amo-crm__list d-flex dir-col"
    >
      <a @click="btnClick('whatsapp')" target="_blank" :href="contactInfo?.whatsapp?.url || '#'" class="amo-crm__cont" aria-label="Gumbati whatsapp" role="link">
        <div class="amo-crm__list-img">
          <AmoWhatsappIcon class="amo-chat-icon-slide" />
        </div>
      </a>
      <a @click="btnClick('telegram')" target="_blank" :href="contactInfo?.telegram?.url || ''" class="amo-crm__cont" aria-label="Gumbati telegram" role="link">
        <div class="amo-crm__list-img">
          <AmoTelegramIcon class="amo-chat-icon-slide" />
        </div>
      </a>
      <div @click="openModal" class="amo-crm__cont">
        <div class="amo-crm__list-img">
          <AmoPhoneIcon class="amo-phone-icon-slide" />
        </div>
      </div>
      <div @click="handleAmoClick" class="amo-crm__cont">
        <div class="amo-crm__list-img">
          <AmoChatIcon class="amo-chat-icon-slide" />
        </div>
      </div>
    </div>
    <div @click="handleButtonClick" class="amo-crm__inner">
      <div class="amo-crm__inner-img pos-rel">
        <div class="amo-img-anim pos-rel">
          <AmoChatIcon
            class="amo-chat-icon pos-abs"
            :class="{ 'amo-show-icon': chatIconActive && !isSlideOpen && !isChatOpen }"
          />
          <AmoPhoneIcon
            class="amo-phone-icon pos-abs"
            :class="{ 'amo-show-icon': !chatIconActive && !isSlideOpen && !isChatOpen }"
          />
          <div
            class="amo-close"
            :class="{ 'amo-show-close-icon': isSlideOpen || isChatOpen }"
          >
            <AmoXIcon class="amo-close-icon pos-abs" />
          </div>
        </div>
      </div>
    </div>
  </aside>
  <Teleport to="body">
    <Transition name="fade" mode="out-in">
      <BaseModal
        class="booking-modal"
        v-if="isModalOpen"
        title="Contact Form"
        @close="onClose"
      >
        <BookingForm @close="onClose" formType="requestCalls" eventFormType="Contact form from widget" />
      </BaseModal>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.amo-button--main.maincollapsed:before {
  border: none !important;
}
.amo-crm {
  z-index: 10000;
  right: 17px;
  bottom: 17px;
  @include easeOut(350ms, transform);

  @include maxq(mobile) {
    // display: none;
  }
  &.amo-crm-open {
    .amo-crm__list {
      opacity: 1;
      transform: translateY(-15px);
      @include easeOut(225, all);
    }
    .amo-img-anim {
      background-color: #d6d6d6;
    }
    .amo-crm__inner {
      animation: initial;
      transform: scale(0.8);
      @include easeOut(225, all);
      .amo-crm__inner-img::before,
      .amo-crm__inner-img::after {
        display: none !important;
      }
      .amo-img-anim::before,
      .amo-img-anim::after {
        display: none !important;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
    right: 100px;
    bottom: 20px;
    min-width: 90px;
    max-width: 300px;
    min-height: 56px;
    opacity: 1;
    padding: 10px;
    z-index: 9999;
    background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 1px 5px 8px 2px rgba(0, 0, 0, 0.2);
    border: 2px solid $mango;
    cursor: pointer;
    @include maxq(desktop-2k) {
      min-width: 70px;
      max-width: 300px;
      min-height: 56px;
    }
    @include maxq(desktop-lg) {
      right: 96px;
      // bottom: 85px;
      min-width: 45px;
      max-width: 260px;
      min-height: 56px;
    }
    @include maxq(mobile) {
      display: none;
      right: 85px;
      bottom: 15px;
    }
    // &-triangle {
      // position: absolute;
      // z-index: 3;
      // right: -14px;
      // bottom: 22px;
      // transform: rotate(-90deg);
      // border: 8px solid;
      // border-color: $mango transparent transparent transparent;
    // }
    &-triangle {
      position: absolute;
      z-index: 3;
      right: -5px;
      bottom: 22px;
      display: block;
      width: 8px;
      height: 8px;
      background: #fff;
      border-right: 2px solid $mango;
      border-bottom: 2px solid $mango;
      transform: rotate(-45deg);
    }
    &-icon {
      right: -13px;
      bottom: 20px;
      transform: rotate(90deg);
    }
    p {
      color: $black;
      font-size: 28px;
      line-height: 34px;
      overflow: hidden;
      word-break: break-word;
      max-height: 142px;
      @include clamp(3);
      @include maxq(desktop-lg) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  &__cont {
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    @include maxq(mobile) {
      width: 40px;
      height: 40px;
    }
  }
  &__cont:last-child {
    margin-bottom: initial;
  }
  .amo-phone-icon {
    position: absolute;
    width: 32px;
    height: 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__list {
    opacity: 0;
    @include easeOut(225, all);
    @include maxq(desktop-lg) {
      right: 17px;
      bottom: 40px;
    }

    &-img {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: $mango;
      border-radius: 50%;
      height: 100%;
      width: 100%;

      .amo-chat-icon-slide {
        height: 25px;
        @include maxq(mobile) {
          height: 20px;
        }
      }
      .amo-phone-icon-slide {
        position: absolute;
        width: 32px;
        height: 28px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include maxq(mobile) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  &__inner {
    cursor: pointer;
    z-index: 10000;
    // right: 10px;
    // bottom: 17px;
    width: 60px;
    height: 60px;
    @include easeOut(350ms, transform);
    @include maxq(desktop-2k) {
      // right: 10px;
      // bottom: 10px;
    }
    @include maxq(desktop-lg) {
      //   right: 17px;
      //   bottom: 17px;
    }
    @include maxq(mobile) {
      bottom: 17px;
      right: 17px;
      width: 50px;
      height: 50px;
    }
    @mixin animation($color: $mango) {
      &::before,
      &::after {
        content: "";
        background-color: $color;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        z-index: 0;
        top: 0;
        left: 0;
        z-index: -1;
        animation: button-wave infinite 2.6s 0s linear;
      }
      &::after {
        animation-delay: 1.3s;
      }
    }
    &-img {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      @mixin animation-large($color: $mango) {
        &::before,
        &::after {
          content: "";
          background-color: transparent;
          border: 1px solid $color;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          z-index: 0;
          top: 0;
          left: 0;
          z-index: -1;
          animation: button-wave-large infinite 1.5s;
        }
        &::after {
          animation-delay: 3s;
        }
      }

      @include animation;
      .amo-img-anim {
        background-color: $mango;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        @include animation-large;
      }
      .amo-close {
        width: 100%;
        height: 100%;
        opacity: 0;
        @include easeOut(225, all);
        &.amo-show-close-icon {
          opacity: 1;
          transform: rotate(90deg);
          @include easeOut(225, all);
        }
        &-icon {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include easeOut(225, all);
        }
      }
      .amo-chat-icon {
        &.amo-show-icon {
          opacity: 1;
          @include easeOut(225, all);
        }
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        @include easeOut(225, all);
      }

      .amo-phone-icon {
        &.amo-show-icon {
          opacity: 1;
          @include easeOut(225, all);
        }
        opacity: 0;
        @include easeOut(225, all);
      }
    }
  }
  @keyframes button-wave {
    0% {
      transform: scale(1, 1);
      opacity: 0.45;
    }
    40% {
      opacity: 0.45;
    }
    100% {
      transform: scale(1.35, 1.35);
      opacity: 0;
    }
  }
  @keyframes button-wave-large {
    0% {
      transform: scale(1, 1);
      opacity: 1;
    }
    50% {
      transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      transform: scale(2, 2);
      opacity: 0;
    }
  }
}
</style>

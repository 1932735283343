<template>
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#000000"
      d="m64 448 832-320-128 704-446.08-243.328L832 192 242.816 545.472 64 448zm256 512V657.024L512 768 320 960z"
    />
  </svg>
</template>

<style lang="scss"></style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="28" viewBox="0 0 31 28">
    <path
      fill="#1f1f1f"
      fill-rule="evenodd"
      d="M23.29 13.25V2.84c0-1.378-1.386-2.84-2.795-2.84h-17.7C1.385 0 0 1.462 0 2.84v10.41c0 1.674 1.385 3.136 2.795 2.84H5.59v5.68h.93c.04 0 .29-1.05.933-.947l3.726-4.732h9.315c1.41.296 2.795-1.166 2.795-2.84zm2.795-3.785v4.733c.348 2.407-1.756 4.558-4.658 4.732h-8.385l-1.863 1.893c.22 1.123 1.342 2.127 2.794 1.893h7.453l2.795 3.786c.623-.102.93.947.93.947h.933v-4.734h1.863c1.57.234 2.795-1.02 2.795-2.84v-7.57c0-1.588-1.225-2.84-2.795-2.84h-1.863z"
    />
  </svg>
</template>

<style lang="scss"></style>
